import { useEffect, useRef, useState } from "react";
import Modal from "../../../atom/modal";
import SearchBar from "../../../atom/search";
import { UserIcon } from "../../../utils/icons/defaultIcons";
import * as loaderLottie from "../../../utils/lottie/loaderLottie.json";
import * as noData from "../../../utils/lottie/noData.json";
import FadeIn from "react-fade-in";
import ThemedButton from "../../../atom/ThemedButton/themedButton";
import { addSmsUser, getExtensionsData, getToken } from "../../../services/api";
import Lottiefy from "../../../atom/lottie/lottie";
import Notification from "../../../atom/Notification";

let pagNumber = 0;

function AddSmsUserModal(props: any) {
  let fetching = false;
  const loadMoreRef = useRef(null);
  const { setAddUserModal, setPageLoader, tableData } = props;
  const [loader, setLoader] = useState<boolean>(true);
  const [extensionLazyLoading, setExtensionLazyLoading] =
    useState<boolean>(true);
  const [handleKeyPress, setHandleKeyPress] = useState<boolean>(true);

  const [buttonLoader, setButtonloader] = useState<boolean>(false);
  const [searchtext, setSearchText] = useState<any>("");

  const [noResultsFound, setNoResultsFound] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>([]);
  const observerRef = useRef(null); // Ref for the observer element
  const [limit, setLimit] = useState(10); // Number of items to fetch per call
  const [offset, setOffset] = useState(0); // Offset for the next API call
  // const [userData1, setUserData1] = useState<any>([
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  //   {
  //     extension: "100",
  //     first_name: "Shiva",
  //     last_name: "T",
  //     uuid: "4ca82c41-a591-4d20-a48c-0c4495bf034f",
  //   },
  // ]);

  const getSmsUserData = (search_item: string = "", loader: boolean = true) => {
    console.log("observerRefobserverRef");
    if (fetching) return;
    fetching = true;
    setLoader(loader);
    setOffset((prevOffset) => prevOffset + limit); //
    let payload: any = {
      limit: limit,
      pageId: pagNumber + 1,
      sort_column: "extension",
      sort_direction: "ASC",
      searchtext: search_item,
    };
    getExtensionsData(payload, getToken()).then((result: any) => {
      const resultData = result?.data?.data;
      if (!result?.success || resultData?.length === 0) {
        setNoResultsFound(true);
        setLoader(false);
      } else {
        setLoader(false);
        setNoResultsFound(false);
      }
      fetching = false;
      pagNumber++;
      const available_users = result?.data?.data.filter(
        (node: any) =>
          !tableData.some((item: any) => node?.uuid == item?.extension_id) &&
          node?.location === false
      );
      if (result?.data?.data.length < limit) {
        setExtensionLazyLoading(false);
      }
      const filtered_Users = available_users?.filter((node: any) => {
        const fullName = `${node?.first_name || ""} ${
          node?.last_name || ""
        }`.toLowerCase();
        const extension = node?.extension?.toString().toLowerCase() || "";
        const searchLower = search_item.toLowerCase();
        return (
          fullName.includes(searchLower) || extension.includes(searchLower)
        );
      });
      console.log("filtered_Users", filtered_Users, "text", search_item); //filtered_Users
      if (filtered_Users?.length === 0) {
        setNoResultsFound(true);
      } else {
        setNoResultsFound(false);
      }
      setUserData((prev: any) => [...prev, ...filtered_Users]); // Append new data

      // setUserData(filtered_Users);
    });
  };

  const handleAddSmsUser = () => {
    setButtonloader(true);
    const payload = userData
      ?.filter((node: any) => node.checked === true)
      .map((result: any) => result?.uuid);
    addSmsUser({ user_ids: payload }).then((result: any) => {
      console.log("result", result);
      if (!result?.success) {
        Notification("Failed", result?.message, "danger");
        setButtonloader(false);
      } else if (payload?.length === 0) {
        Notification("Failed", "Select any user", "danger");
        setButtonloader(false);
      } else {
        Notification("Success", "SMS user added successfully", "success");
        setAddUserModal(false);
        setButtonloader(false);
        setPageLoader(true);
      }
    });
  };

  const handleChecked = (data: any) => {
    const CopyUserData = [...userData];
    CopyUserData.forEach((node: any) => {
      if (node.extension === data.extension) {
        node.checked = !data?.checked || false;
      }
    });
    setUserData(CopyUserData);
  };

  const handleSearch = (data: string) => {
    setSearchText(data);
    console.log("searchdata", data);
    pagNumber = 0;
    // onHandleFetch({ searchtext: data, pageNumber: 1, limit: perPage });
    setUserData((prev: any) => []);
    setExtensionLazyLoading((prev: any) => true);
    getSmsUserData(data, true);
  };

  useEffect(() => {
    pagNumber = 0;
    getSmsUserData("", true);
  }, []);

  useEffect(() => {
    if (extensionLazyLoading) {
      const observer = new IntersectionObserver(
        (entries) => {
          const target = entries[0];
          if (target.isIntersecting) {
            getSmsUserData("", false); // Load more data when the target element is in view
          }
        },
        { root: observerRef.current, threshold: 0.8 } // Trigger when 100% of the target is visible
      );

      if (loadMoreRef.current) {
        observer.observe(loadMoreRef.current);
      }

      return () => {
        if (loadMoreRef.current) {
          observer.unobserve(loadMoreRef.current); // Cleanup observer
        }
        observer.disconnect();
      };
    }
  }, [userData]); // Dependency array includes the ref
  return (
    <div className="">
      <Modal title={"Add SMS User"} closeEvent={() => setAddUserModal(false)}>
        <div className="p-2">
          <SearchBar
            placeholder={"Search by name, extension"}
            searchText={searchtext}
            setSearchText={setSearchText}
            handleSearch={handleSearch}
            handleKeyPress={handleKeyPress}
            setHandleKeyPress={setHandleKeyPress}
          />
          <div
            ref={observerRef}
            className="mt-4 md:w-[650px]  h-[430px] overflow-y-auto"
          >
            {noResultsFound && (
              <div className="h-[430px]  grid place-items-center font-semibold">
                <FadeIn>
                  <Lottiefy loop={true} json={noData} height={80} width={80} />

                  <p className="">No Data Available</p>
                </FadeIn>
              </div>
            )}
            {loader ? (
              <div className="h-full  grid place-items-center">
                <Lottiefy
                  loop={true}
                  json={loaderLottie}
                  height={80}
                  width={80}
                />
              </div>
            ) : (
              <FadeIn>
                {userData?.map((node: any, index: number) => {
                  return (
                    <div
                      key={node?.extnesion}
                      onClick={() => handleChecked(node)}
                      className="flex justify-between border-b border-b-[#d1d1d1] pt-4 pb-5 px-2 hover:rounded-md hover:bg-[#FEF4E9] hover:cursor-pointer "
                    >
                      <div className="flex gap-1">
                        <div className="">
                          <UserIcon />
                        </div>
                        <p>{node?.extension}</p>
                      </div>
                      <p>
                        {node?.first_name} {node?.last_name}
                      </p>

                      <div>
                        <input
                          type="checkbox"
                          id={`checkbox_${index}`}
                          className={`w-4 h-4  rounded-lg cursor-pointer accent-[#F78F1E]`}
                          checked={node?.checked ? node.checked : false}
                          // onChange={() => handleChecked(node)}
                        />
                      </div>
                    </div>
                  );
                })}
                {extensionLazyLoading ? (
                  <div
                    ref={loadMoreRef}
                    className="flex justify-center py-2 text-[gray] text-sm"
                  >
                    Loading...
                  </div>
                ) : (
                  <div className="flex justify-center py-2 text-[gray] text-sm">
                    No more data
                  </div>
                )}
              </FadeIn>
            )}
          </div>
          {!loader && !noResultsFound && (
            <div className="text-right w-full  mt-6">
              <ThemedButton
                children={"Cancel"}
                icon="custom"
                theme="secondary"
                click={() => setAddUserModal(false)}
              />
              <ThemedButton
                children={"Add"}
                icon="custom"
                theme="primary"
                click={handleAddSmsUser}
                loading={buttonLoader}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default AddSmsUserModal;
