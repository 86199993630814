import axios from "axios";
import {
  getClientFromEmail,
  tryKeyCloakLogOut,
  tryKeyCloakLogin,
  tryKeyCloakRefresh,
  verifyClient,
} from "../../services/api";
import {
  tryKeyCloakLoginOptions,
  tryLoginOptions,
} from "../../services/interfaces";
import CryptoJS from "crypto-js";
import { activityState } from "../../config/constants";
import { Cookies } from "react-cookie";

const cookies = new Cookies();
const Authv2 = {
  isAuthenticated: activityState.LOADING,
  token: {},
  realm: "",
  client: "",
  userId: "",
  timeOut: 0,
  generatedTime: new Date(),
  statusCode: 0,
  async tryLogin(options: tryLoginOptions, callback: Function) {
    const res: boolean = await handleClient(options.username);
    // debugger
    if (res) {
      const payload: tryKeyCloakLoginOptions = {
        username: options.username,
        password: options.password,
        grant_type: "password",
        sname: Authv2.client,
        realm: Authv2.realm,
      };
      const login: any = await tryKeyCloakLogin(payload);
      if (login.status === 200) {
        const realm = {
          sname: Authv2.client,
          realm: Authv2.realm,
          userId: Authv2.userId,
        };
        var data = JSON.stringify({ realm });
        var ciphertext = CryptoJS.AES.encrypt(data, "ncs").toString();
        // cookies.set('ncs_rlm', ciphertext, { path: '/' });
        window.localStorage.setItem("ncs_rlm", ciphertext);
        const loginData = login.data;
        data = JSON.stringify({ loginData });
        var ciphertext = CryptoJS.AES.encrypt(data, Authv2.userId).toString();
        window.localStorage.setItem("ncs_ac", ciphertext);
        // cookies.set('ncs_ac', ciphertext, { path: '/' });
        Authv2.token = login.data;
        Authv2.isAuthenticated = activityState.SUCCESS;
        callback(true, login.data);
      } else {
        Authv2.isAuthenticated = activityState.ERROR;
        callback(false, "Invalid User Credentials");
      }
    } else {
      Authv2.isAuthenticated = activityState.ERROR;
      callback(false, "Invalid User Credentials");
    }
  },
  signIn(tokens: any, onSignInComplete: Function) {
    Authv2.isAuthenticated = activityState.SUCCESS;
    window.localStorage.setItem("ncs_ac", JSON.stringify(tokens));
    // cookies.set('ncs_ac', JSON.stringify(tokens), { path: '/', domain: 'qwerty' });
    setTimeout(onSignInComplete, 100);
  },
  async checkClient(onCheckClientComplete: Function) {
    Authv2.isAuthenticated = activityState.LOADING;
    var ncs_rlm: string = window.localStorage.getItem("ncs_rlm") || "";
    // var ncs_rlm: string = cookies.get("ncs_rlm") || '';
    if (ncs_rlm) {
      var bytes = CryptoJS.AES.decrypt(ncs_rlm, "ncs");
      const rlm: any = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      const ncs_ac = window.localStorage.getItem("ncs_ac");
      // const ncs_ac = cookies.get("ncs_ac");
      if (ncs_ac) {
        var bytes = CryptoJS.AES.decrypt(ncs_ac, rlm.realm.userId);
        const tokens = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // Authv2.isAuthenticated = activityState.LOADING;
        const payload: tryKeyCloakLoginOptions = {
          refresh_token: tokens.loginData.refresh_token,
          grant_type: "refresh_token",
          sname: rlm.realm.sname,
          realm: rlm.realm.realm,
        };
        const login: any = await tryKeyCloakRefresh(payload);
        console.log("loginloginloginloginlogin", login);
        if (login.status === 200) {
          const realm = {
            sname: rlm.realm.sname,
            realm: rlm.realm.realm,
            userId: rlm.realm.userId,
          };
          var data = JSON.stringify({ realm });
          var ciphertext = CryptoJS.AES.encrypt(data, "ncs").toString();
          window.localStorage.setItem("ncs_rlm", ciphertext);
          // cookies.set('ncs_rlm', ciphertext, { path: '/', domain: 'qwerty' });
          const loginData = login.data;
          data = JSON.stringify({ loginData });
          var ciphertext = CryptoJS.AES.encrypt(
            data,
            rlm.realm.userId
          ).toString();
          window.localStorage.setItem("ncs_ac", ciphertext);
          // cookies.set('ncs_ac', ciphertext, { path: '/', domain: 'qwerty' });
          Authv2.token = login.data;

          Authv2.isAuthenticated = activityState.SUCCESS;
          setTimeout(onCheckClientComplete(Authv2.token), 100);
        } else if (login.status === 400) {
          //   alert("Session Expired");
          Authv2.isAuthenticated = activityState.ERROR;
          Authv2.token = {};
          clearAxios();
          window.localStorage.clear();
          Authv2.token = login.status;

          //   window.location.pathname = "/app";
          setTimeout(onCheckClientComplete(Authv2.token), 100);
        } else {
          Authv2.isAuthenticated = activityState.ERROR;
          Authv2.token = {};
          window.localStorage.clear();
          clearAxios();
          setTimeout(onCheckClientComplete(Authv2.token), 100);
        }
      }
    } else {
      Authv2.token = {};
      clearAxios();
      Authv2.isAuthenticated = activityState.IDLE;
      window.localStorage.clear();
      setTimeout(onCheckClientComplete(Authv2.token), 100);
    }
  },
  async syncCookie(onSyncComplete: Function) {
    var ncs_rlm: string = window.localStorage.getItem("ncs_rlm") || "";
    // var ncs_rlm: string = cookies.get("ncs_rlm") || '';

    if (ncs_rlm) {
      var bytes = CryptoJS.AES.decrypt(ncs_rlm, "ncs");
      const rlm: any = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      const ncs_ac = window.localStorage.getItem("ncs_ac");
      // const ncs_ac = cookies.get("ncs_ac");
      if (ncs_ac) {
        var bytes = CryptoJS.AES.decrypt(ncs_ac, rlm.realm.userId);
        const tokens = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        Authv2.isAuthenticated = activityState.SUCCESS;
        Authv2.token = tokens.loginData;
      } else {
        Authv2.isAuthenticated = activityState.ERROR;
        Authv2.token = {};
        clearAxios();
        cookies.remove("ncs_rlm", { path: "/" });
      }
    } else {
      Authv2.isAuthenticated = activityState.ERROR;
      Authv2.token = {};
      clearAxios();
    }
    setTimeout(onSyncComplete(Authv2.token), 100);
  },
  async signOut(refresh_token: string, onSignOutComplete: VoidFunction) {
    var ncs_rlm: string = window.localStorage.getItem("ncs_rlm") || "";
    // var ncs_rlm: string = cookies.get("ncs_rlm") || '';
    if (ncs_rlm) {
      var bytes = CryptoJS.AES.decrypt(ncs_rlm, "ncs");
      const rlm: any = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      const payload: tryKeyCloakLoginOptions = {
        refresh_token: refresh_token,
        grant_type: "refresh_token",
        sname: rlm.realm.sname,
        realm: rlm.realm.realm,
      };
      const result = await tryKeyCloakLogOut(payload);
      clearAxios();
      cookies.remove("ncs_rlm", { path: "/" });
      cookies.remove("ncs_ac", { path: "/" });
      Authv2.isAuthenticated = activityState.ERROR;
      Authv2.token = {};
      window.localStorage.clear();
    }
    cookies.remove("ncs_rlm", { path: "/" });
    cookies.remove("ncs_ac", { path: "/" });
    window.localStorage.clear();
    setTimeout(onSignOutComplete, 100);
  },
};
async function handleClient(email: string): Promise<boolean> {
  const response: any = await getClientFromEmail(email);
  if (response.status === 200) {
    Authv2.realm = response.data.realm;
    Authv2.client = response.data.realm;
    Authv2.userId = response.data.user_uuid;
    return true;
  } else {
    return false;
  }
}
const clearAxios = () => {
  delete axios.defaults.headers.common["Authorization"];
  cookies.remove("ncs_rlm", { path: "/" });
  cookies.remove("ncs_ac", { path: "/" });
};

export { Authv2 };
