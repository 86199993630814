import FadeIn from "react-fade-in";
import ComponentMap from "../../../atom/componentmap";
import ThemedButton from "../../../atom/ThemedButton/themedButton";
import { useEffect, useState } from "react";
import Wallboard from "./wallboard";
import { getAllDisplayGroups } from "../../../services/api";
import { addChildrensToMapData } from "../../../services/utilities";
import { useLocation, useNavigate } from "react-router-dom";
import { setWallboardData } from "../../../toolkit/reducers/wallboardSettingsSlice";
import { useDispatch } from "react-redux";

function WallboardSettings() {
  const navigate = useNavigate();
  const path = useLocation();
  const dispatch = useDispatch();
  const [launch, setLaunch] = useState<boolean>(false);
  const [wallBoardData, setWallBoardData] = useState<any>([
    {
      title: "Display Group",
      name: "name",
      value: "",
      type: "dropdown",
      displayProps: { label: "name", value: "uuid" },
      childrens: [],
      required: true,
      errormessage: "Display Group is Required",
    },
    {
      title: "Time Interval",
      name: "timeInterval",
      value: "",
      type: "dropdown",
      displayProps: { label: "label", value: "uuid" },
      childrens: [
        { label: "Last Hour", value: "1" },
        { label: "Last 2 hours", value: "2" },
        { label: "Last 4 hours", value: "4" },
        { label: "Last 8 hours", value: "8" },
        { label: "Last 12 hours", value: "12" },
      ],
      required: false,
      errormessage: "Time Interval is Required",
    },
    // {
    //   title: "Include Incoming Calls",
    //   name: "incomingCalls",
    //   type: "checkbox",
    //   value: "",
    // },
  ]);

  const onLaunch = () => {
    dispatch(setWallboardData(wallBoardData));
    navigate(`${path?.pathname}/wallboard`, { replace: false });
  };

  const getDisplayGroupsList = () => {
    getAllDisplayGroups().then((result: any) => {
      if (!result?.success) {
      } else {
        const data = result?.data?.data;
        console.log("reachedhere", data);
        setWallBoardData(
          addChildrensToMapData([...wallBoardData], data, "name")
        );
      }

      console.log("diplayGroups", result);
    });
  };

  useEffect(() => {
    getDisplayGroupsList();
  }, []);
  return (
    <div
      data-testid="wallboardSettings"
      className=" flex justify-center items-center h-[calc(100vh-110px)]"
    >
      <FadeIn>
        <div className="h-fit w-[520px] bg-[#FFFFFF] rounded-lg">
          <div className="text-[16px] font-bold px-7 py-4">
            Wallboard Settings
          </div>

          <div className="mt-4 relative px-5">
            <ComponentMap data={wallBoardData} setData={setWallBoardData} />
          </div>

          <div className=" w-full h-full py-4 flex items-baseline justify-end mt-4 -ml-6 ">
            <ThemedButton
              children={"Launch"}
              icon="custom"
              theme="primary"
              valiData={wallBoardData}
              change={setWallBoardData}
              click={onLaunch}
            />
          </div>
        </div>
      </FadeIn>
    </div>
  );
}
export default WallboardSettings;
